
$.material.init();

var touch = Modernizr.touch;
var mobile = false;
var headerFixed = false;
var $window = $(window);
var $animation_elements = $('.animation-element');

//Vimeo carousel home

//vimeo variables
if ($('#player1').length != 0){
    var video1Stop = false;
    var iframeVim = $('#player1')[0];
    var playerVim = new Vimeo.Player(iframeVim);
    playerVim.setVolume(0);
    var statusVim = 0;    
}

// Used to compensante Chrome/Safari bug (they don't care about scroll bar for width)
function scrollCompensate() {
    var inner = document.createElement('p');
    inner.style.width = "100%";
    inner.style.height = "200px";

    var outer = document.createElement('div');
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild(inner);

    document.body.appendChild(outer);
    var w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    var w2 = inner.offsetWidth;
    if (w1 === w2) { w2 = outer.clientWidth; }

    document.body.removeChild(outer);

    return (w1 - w2);
}

// Resize calculs
function resize() {


    if (mobile == false) {
        if ($(window).width() + scrollCompensate() < 740) {
            // si existe video en carousel lo pusa al no mostrarse en version responsive mobile

            mobile=true;
        } else {
            if ($('.scrollFixed').length != 0 ) {
                $( ".scrollFixed" ).each(function(i) {
                    var $hFull = $(".scrollFixed").closest('.scrollController').find('.hfull');
                    var $sFixed = $(this).height();
                    $hFull.css('min-height',$sFixed);
                });
            }
            //$('body').removeClass('o-hidden');
        }

    } else {
        if ($(window).width() + scrollCompensate() >= 740) {
            if ($('.scrollFixed').length != 0 ) {
                $( ".scrollFixed" ).each(function(i) {
                    var $hFull = $(this).closest('.scrollController').find('.hfull');
                    var $sFixed = $(this).height();
                    $hFull.css('min-height',$sFixed);
                });
            }
            //$('body').removeClass('o-hidden');
            mobile = false;
        } else {

        }
    }
    if (mobile){
        if ($(window).width() + scrollCompensate() >= 520) {
            $(".grid-item").each(function( index ) {
                if (!$(this).hasClass('w2')){
                    $(".grid-item").height(parseInt($(this).width()));
                }
                $(".grid-item.w2").height('');
            });
        } else {
             $(".grid-item").height('');
        }
    } else{
        $(".grid-item").each(function( index ) {
            if (!$(this).hasClass('w2')){
                $(".grid-item").height(parseInt($(this).width()));
            }
            $(".grid-item.w2").height(parseInt($(this).width())*2+12);
        });
    }
}


// Animation elements
$window.on('scroll resize', function () {
    //check_if_in_view();
    headerFix();
    if (($(window).width() + scrollCompensate() >= 740 || mobile) && $('.scrollFixed').length != 0 ) {
       scrollsFixed(); 
    }
});
$window.trigger('scroll');

function headerFix() {
    if ($(window).scrollTop() > 100 && !headerFixed) {
        headerFixed = true;
        $('#header').animate({
            top: -60
        }, 100,'swing', function() {
            $('#header').addClass('fixed').animate({top:0},300,'swing');
        });
    } else if ($(window).scrollTop() < 100 && headerFixed){
        headerFixed = false;
        $('#header').animate({
            top: -60
        }, 100,'swing', function() {
            $('#header').removeClass('fixed').animate({top:0},300,'swing');
        });
    }
}
function scrollsFixed() {
    $( ".scrollFixed" ).each(function(i) {
        var $windowTopPos = $window.scrollTop();
        var $sContainer = $(this).closest('.scrollController');
        var $sFixed = $(this);
            
        if (mobile){
            if ($sContainer.offset().top < $windowTopPos+45){
                $sFixed.css('position','fixed');
                $sFixed.css('top',45);
            } else {
                $sFixed.css('position','').css('top','');
            }
        } else {
            if ($sContainer.offset().top < $windowTopPos + 40){
                if ($sContainer.height() <= $windowTopPos - $sContainer.offset().top + $sFixed.height()){
                    $sFixed.css('position','absolute');
                    $sFixed.css('top',$sContainer.height() - $sFixed.height() + 40);
                } else {
                    $sFixed.css('position','fixed');
                    $sFixed.css('top',45);

                    //$sFixed.css('top',$windowTopPos - $sContainer.offset().top + 40);
                }
            } else {
                $sFixed.css('position','').css('top','').css('bottom','');
            }
        }

    });
}


function check_if_in_view() {
    $elements = $(window);
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function () {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        //var element_bottom_position = (element_top_position + element_height);

        /*check to see if this current container is within viewport
        if (element_top_position < window_bottom_position) {
            $element.addClass('in-view');
            if ($element.hasClass('box-scene')){
                $('.in-view .num-progresive').each(function() {
                    counter($(this));
                });
            }
        } */
    });
}

  

$(function () {

    function onTidioChatApiReady() {
      // Code after chat loaded
      //console.log(window.tidioChatApi);
      $('.btn-contact').find(".chat.cerrado").hide();
      $('.btn-contact').find(".chat.abierto").show();
    }
    if (window.tidioChatApi) {
      window.tidioChatApi.on("ready", onTidioChatApiReady);
    } else {
      document.addEventListener("tidioChat-ready", onTidioChatApiReady);
    }
    if ($('#analysis-spy').length != 0) {
        $('body').scrollspy({ target: '#analysis-spy' , 'offset': 100});
    }
    if ($('#normas-spy').length != 0) {
        $('body').scrollspy({ target: '#normas-spy' , 'offset': 100});
    }
    if ($('#urano-spy').length != 0) {
        $('body').scrollspy({ target: '#urano-spy' , 'offset': 100});
    }
    // Controlar si ha saltado version mobile
    if ($(window).width() + scrollCompensate() < 740) {
        mobile = true;
    }

    $('#mobile-menu .dropdown').on('show.bs.dropdown', function () {
        $('#mobile-menu').addClass('o-hiddenxs');
        console.log('opened dropdown');
    }).on('hide.bs.dropdown', function () {
        $('#mobile-menu').removeClass('o-hiddenxs');
    })


    // Programación slide HomePage
    if ($('#carPrincipal').length != 0) {
        /*if ($(this).hasClass('unique')){
            var swiper = new Swiper('.carousel', {
                pagination: {
                    el: '.scar-pagination',
                    type: 'bullets',
                },
                navigation: {
                    nextEl: '.scar-next',
                    prevEl: '.scar-prev',
                },
                slidesPerView: 1,
                preloadImages: false,
                lazyLoading: true,
                paginationClickable: true,
                spaceBetween: 0,
                speed : 600,
                effect: 'slide',
                loop: true,
                onInit: function (swiper) {
                    $('#carPrincipal').find('.swiper-slide-active').addClass('showBox');
                    if ($('#carPrincipal .showBox').hasClass('videoControl') && !statusVim){
                        playerVim.play();
                        $('#player1').closest('.videoControl').removeClass('play');
                        swiper.autoplay.stop();
                    }
                },
                onSlideChangeStart: function (swiper) {
                    $('#carPrincipal').find('.showBox').removeClass('showBox');
                    $('#carPrincipal').find('.swiper-slide-active').addClass('showBox');
                    setTimeout( function(){
                        
                        if ($('#carPrincipal .showBox').hasClass('videoControl') && !statusVim){
                            playerVim.play();
                            $('#player1').closest('.videoControl').removeClass('play');
                            swiper.autoplay.stop();
                        } else if (statusVim) {
                            playerVim.pause();
                            $('#player1').closest('.videoControl').addClass('play');
                            swiper.autoplay.start();
                        }
                        
                    }, 100);
                }
            });
        } else {*/
    //console.log("o");
            var swiper = new Swiper('.carousel', {
                pagination: {
                    el: '.scar-pagination',
                    type: 'bullets',
                    clickable:true
                },
                navigation: {
                    nextEl: '.scar-next',
                    prevEl: '.scar-prev',
                },
                init:false,
                slidesPerView: 1,
                preloadImages: false,
                lazyLoading: true,
                paginationClickable: true,
                spaceBetween: 0,
                speed : 600,
                effect: 'slide',
                loop: false,
                autoplay: 
                {
                  delay: 6000,
                },
                on:{
                    /*init: function (swip) {
                       
                    },*/
                    slideChangeTransitionStart: function (swip) {
                        //console.log("change");
                        $('#carPrincipal').find('.showBox').removeClass('showBox');
                        $('#carPrincipal').find('.swiper-slide-active').addClass('showBox');
                        setTimeout( function(){
                            if ($('#carPrincipal .showBox').hasClass('videoControl') && !statusVim){
                                playerVim.play();
                                $('#player1').closest('.videoControl').removeClass('play');
                                swiper.autoplay.stop();
                            } else if (statusVim) {
                                playerVim.pause();
                                $('#player1').closest('.videoControl').addClass('play');
                                swiper.autoplay.start();
                            }
                        }, 100)
                    }
                }
            });
            swiper.on('init', function() {
                 //console.log("e");
                $('#carPrincipal').find('.swiper-slide-active').addClass('showBox');
                if ($('#carPrincipal .showBox').hasClass('videoControl') && !statusVim){
                    playerVim.play();
                    $('#player1').closest('.videoControl').removeClass('play');
                    console.log(swiper);
                    swiper.autoplay.stop();
                }
             });
            // init Swiper
            swiper.init();
        //}
    }

    if ($('#player1').length != 0){
        // Vimeo functions

        // When the player is ready, add listeners for pause, finish, and playProgress
        playerVim.ready().then(function() {
            playerVim.setLoop(true);
            playerVim.setVolume(0);
            playerVim.on('pause', onPause);     
            playerVim.on('play', onPlay);     
            playerVim.on('ended', onFinish);     
        });

        function onPlay() {
            playerVim.setVolume(0);
            statusVim = 1;
        }
        function onPause() {
            statusVim = 0;
        }
        function onFinish() {
            statusVim = 0;
            swiper.autoplay.start();
        }

        $(document).on("click", ".videoControl", function() {
            if (!mobile){
                if (!statusVim){
                    swiper.autoplay.stop();
                    playerVim.play();
                    $('#player1').closest('.videoControl').addClass('play');
                    video1Stop=false;
                } else {
                    playerVim.pause();
                    $('#player1').closest('.videoControl').removeClass('play');
                    swiper.autoplay.start();
                    video1Stop=true;
                }
            } 
        });
    }

    if ($('.aside-right .list-prop').length > 0){
        $('.aside-right .list-prop').height(($('.content-left').height()-170)+'px');
    }
    resize();
});

$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();
    var mobileTop = 0;
    if (mobile){mobileTop = 52}
    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top - mobileTop
    }, 400);
});

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});

function sendContactoUranolab(cont_id){
    $('.form_'+cont_id+' .btn-enviar').hide();
    $('.form_'+cont_id+' .btn-enviando').show();
    $('.form_'+cont_id+' .btn-enviarForm').prop("disabled",true);
    var csrfToken = $('meta[name="csrf-token"]').attr("content");
    var json = $('.form_'+cont_id).serializeObject();
    var form = document.querySelector('.form_'+cont_id);
    var formData = new FormData(form);
    
    formData.append("_csrf", csrfToken);
    formData.append("lang_id", lang_id);
    formData.append("cont_id", cont_id);
    formData.append("json", JSON.stringify(json));
    formData.append("page", window.location.href);
    $.ajax({
        url: "/uranolab/sendformcontactouranolab",
        type: 'POST',
        data: formData,
        dataType: 'json',
        async: false,
        success: function (data) {
            if(data.code==1){
                $('.form_'+cont_id+' .msgOK').show();
                $('.form_'+cont_id).addClass('sended');
                if ($(window).width() < 740){
                    $("html, body").animate({ scrollTop: $('.form_'+cont_id+' .msgOK').offset().top-120 }, 400);
                } else{
                    
                    $("html, body").animate({ scrollTop: $('.form_'+cont_id+' .msgOK').offset().top-200 }, 400);
                }
            }else{
                $('.form_'+cont_id+' .msgKO').show();
                if ($(window).width() < 740){
                    $("html, body").animate({ scrollTop: $('.form_'+cont_id+' .msgKO').offset().top-120 }, 400);
                } else{
                    $("html, body").animate({ scrollTop: $('.form_'+cont_id+' .msgKO').offset().top-200 }, 400);
                }
            }
            $('.form_'+cont_id+' .btn-enviarForm').prop("disabled",false);
            $('.form_'+cont_id+' .btn-enviar').show();
            $('.form_'+cont_id+' .btn-enviando').hide();
        },
        cache: false,
        contentType: false,
        processData: false
    })
}

function sendNewsletter(cont_id){
    $('.form_'+cont_id+' .btn-enviar').hide();
    $('.form_'+cont_id+' .btn-enviando').show();
    $('.form_'+cont_id+' .btn-enviarForm').prop("disabled",true);
    var csrfToken = $('meta[name="csrf-token"]').attr("content");
    var json = $('.form_'+cont_id).serializeObject();
    var form = document.querySelector('.form_'+cont_id);
    var formData = new FormData(form);
    
    formData.append("_csrf", csrfToken);
    formData.append("lang_id", lang_id);
    formData.append("cont_id", cont_id);
    formData.append("json", JSON.stringify(json));
    formData.append("page", window.location.href);
    $.ajax({
        url: "/uranovet/sendformnewsletter",
        type: 'POST',
        data: formData,
        dataType: 'json',
        async: false,
        success: function (data) {
            if(data.code==1){
                $('.form_'+cont_id+' .msgOK').show();
                $('.form_'+cont_id).addClass('sended');
                $('.form_'+cont_id+' input[type="text"],.form_'+cont_id+' input[type="email"]').val("");
                if ($(window).width() < 740){
                    $("html, body").animate({ scrollTop: $('.form_'+cont_id+' .msgOK').offset().top-120 }, 400);
                } else{
                    
                    $("html, body").animate({ scrollTop: $('.form_'+cont_id+' .msgOK').offset().top-200 }, 400);
                }
            }else{
                $('.form_'+cont_id+' .msgKO').show();
                if ($(window).width() < 740){
                    $("html, body").animate({ scrollTop: $('.form_'+cont_id+' .msgKO').offset().top-120 }, 400);
                } else{
                    $("html, body").animate({ scrollTop: $('.form_'+cont_id+' .msgKO').offset().top-200 }, 400);
                }
            }
            $('.form_'+cont_id+' .btn-enviarForm').prop("disabled",false);
            $('.form_'+cont_id+' .btn-enviar').show();
            $('.form_'+cont_id+' .btn-enviando').hide();
        },
        cache: false,
        contentType: false,
        processData: false
    })
}
$(document).on("click", "#btn-menu", function () {
    if ($('#mobile-menu').hasClass('open')) {
        $('#mobile-menu').removeClass('open');
        $('#head-mobile').removeClass('mopen');
        $(this).removeClass('active');
        $('body').removeClass('o-hidden');
    } else {
        $('#mobile-menu').addClass('open').removeClass('o-hiddenxs');
        $('#head-mobile').addClass('mopen');
        $(this).addClass('active');
        $('body').addClass('o-hidden');
    }
})

function alt120_popup(){

        $(".c-modal__call-popup").on("click", function() {
            $(".custom-model-main-popup").fadeIn();
            $('html').addClass('no-scroll');
        });

        $(".c-modal__close, .c-modal__overlay").on("click", function(){
            $(".custom-model-main-popup").fadeOut();
            $('html').removeClass('no-scroll');
        });
    }
    
$(window).resize(function () {
    resize();
});

